import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "components/layout"
import SEO from "components/seo"
import Button from "components/Buttons/Button"
import ButtonGroup from "components/Buttons/ButtonGroup"
import { Section, Container } from "../components/Layouts"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
function SinglePublicityRelease(props) {
  let page = props.data.allPublicityReleasesJson.nodes[0]

  const styles = {
    height: "100%",
    minHeight: "45vh"
  }
  return (
    <Layout>
      <SEO noGtag robots="noindex" />
      <Section>
        <Container>
          <div style={styles}>
            <MarkdownViewer markdown={page.blurb} />
            <ButtonGroup isCenteredMobile className="mt-2">
              {page.buttons.map(buttonObj => {
                let button = buttonObj.button
                return (
                  <Button
                    external={button.destination === "external"}
                    tel={button.destination === "tel"}
                    key={button.href}
                    buttonText={button.buttonText}
                    href={button.href}
                  />
                )
              })}
            </ButtonGroup>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

SinglePublicityRelease.propTypes = {
  data: PropTypes.object.isRequired
}

export const pageQuery = graphql`
  query MyQuery($title: String!) {
    allPublicityReleasesJson(filter: { title: { eq: $title } }) {
      nodes {
        blurb
        buttons {
          button {
            destination
            appearance
            buttonText
            href
          }
        }
        title
      }
    }
  }
`

export default SinglePublicityRelease
